import { CenteredSpinner, THEME } from "@digitallab/grid-common-components";
import { Notify } from "@digitallab/grid-common-components";

import { useHistory, useLocation } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { changeTimeFormat } from "../features/log-book/helpers";
import { StepperForm } from "./StepperForm";
import { useEffect, useState } from "react";
import ItemWrapper from "../features/log-book/log-book-item-form-dialog/item-context/ItemWrapper";
import { actionLogValidationSchema } from "./actionLogValidationSchema";
import { runLogValidationSchema } from "./runLogValidationSchema";
import { useSearchParameters } from "../components/shared/useSearchParameters";
import { RUN_STATUS_FIELD } from "../constants";
import { DigitalLabLogbookInstrumentModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { withApollo, WithApolloClient } from "react-apollo";
import { useSelector } from "react-redux";

import { createActionLogBulkVariables, createRunLogBulkMutation, createRunLogBulkVariables } from "./mutationHelpers";
import { VALIDATE_ELN_ID } from "../gql/logBooksapi/queries";

import { loadEquipmentsData } from "./loadEquipmentData";
import { CREATE_BULK_DIGITAL_LAB_LOGBOOK_ACTION_LOG } from "../gql/logBooksapi";

export interface ISelectValues {
  key: string;
  value: string;
}
interface IEndorAttachment {
  createdAt: string;
  addedBy: string;
  status: string;
  filename: string;
  fileDescription: string;
  checksum: null;
  targetModule: string;
  fielId: string;
  groupId: string;
  bucketRegion: string;
  groupMetadataVersion: string;
}
export interface IActionLogFormValues {
  actionDate: Date;
  actionTime: string;
  action: ISelectValues;
  description: string;
  ecrNumber?: string;
  gxpReady: ISelectValues;
  operatorUserName?: string;
  systemStatus: ISelectValues;
  externalDocument?: { link: string; name: string }[];
  attachments?: IEndorAttachment[];
  updatedSoftwareVersion?: { newValue: string; shouldBePublished: boolean };
  logbookConfigurationDetails: { newValue: string; shouldBePublished: boolean };
}

export interface IRunLogFormValues {
  runStartDate: Date;
  runEndTime?: string;
  runEndDate: Date;
  runStartTime?: string;
  runStatus: keyof typeof RUN_STATUS_FIELD;
  runIdentification?: string;
  samplesProcessed?: string;
  numberOfRuns?: string;
  description: string;
  defectId?: string;
  eLNid?: string;
  mediaType?: ISelectValues[];
  tipsUsed?: ISelectValues;
  operatorUserName?: string;
  assay: string[];
}

export const KEEP_STATUS_VALUE = {
  key: "KEEP_CURRENT_STATUS",
  value: "Keep current status"
};
const DEFAULT_INITIAL_VALUE = {
  newValue: null,
  shouldBePublished: null
};

const actionLogInitialValues = {
  actionDate: new Date(),
  actionTime: changeTimeFormat(new Date()),
  gxpReady: KEEP_STATUS_VALUE,
  updatedSoftwareVersion: DEFAULT_INITIAL_VALUE,
  systemStatus: KEEP_STATUS_VALUE,
  logbookConfigurationDetails: DEFAULT_INITIAL_VALUE
};

const runLogInitialValues = {
  runStartDate: new Date(),
  runEndDate: new Date(),
  assay: [], // RunSelectWithOwnProps and CustomAutocompleteWithOwnProp that are used for assay input - needs to get an array to work correctly, for undefined it will crush
  mediaType: [] //Here - if log created with null value, then not possible to open it later for edition.
};

export type InstrumentDetailsForStepperModelType = Pick<
  DigitalLabLogbookInstrumentModelType,
  | "belongingToGroup"
  | "configurationDetails"
  | "equipmentId"
  | "equipmentModel"
  | "equipmentNickName"
  | "inventoryId"
  | "location"
  | "qualificationStatus"
  | "serialNumber"
  | "siteName"
  | "status"
  | "systemStatus"
>;

interface IEquipmentList {
  equipmentIdList: string[];
}

interface IEquipmentListWithDetails {
  equipmentListWithDetails: InstrumentDetailsForStepperModelType[];
}

interface ILogFormParams extends IEquipmentList {
  actionLogForm?: IActionLogFormValues;
  runLogForm?: IRunLogFormValues;
}

export type ILogFormValues = IEquipmentListWithDetails & (IActionLogFormValues | IRunLogFormValues);

export const StepperContainer = withApollo(({ client }: { client: WithApolloClient<any> }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { pathname } = useLocation();
  const { equipmentIdList, runLogForm, actionLogForm } = useSearchParameters() as unknown as ILogFormParams;
  const [equipmentListWithDetails, setEquipmentListWithDetails] = useState<
    InstrumentDetailsForStepperModelType[] | undefined
  >();
  const isRunLog = pathname.includes("/logs/run");
  const history = useHistory();
  const systemStatuses = useSelector(
    (store) => (store as { runLogsForm: { systemStatuss: ISelectValues[] } }).runLogsForm.systemStatuss
  );
  const user = useSelector((state) => (state as { user: any }).user);

  useEffect(() => {
    const getEquipments = async () => {
      if (!equipmentListWithDetails || equipmentListWithDetails?.length === 0) {
        if (equipmentIdList?.length > 0) {
          const foundEquipment = await loadEquipmentsData(equipmentIdList, client, history, pathname);
          setEquipmentListWithDetails(foundEquipment);
        } else setEquipmentListWithDetails([]);
      }
    };
    getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentIdList]);

  const eLNidValidationCheck = async (eLNid: string) => {
    try {
      const result = await client.query({
        query: VALIDATE_ELN_ID,
        fetchPolicy: "no-cache",
        variables: {
          eLNid
        }
      });
      return result?.data?.getElnIdValidation;
    } catch (err) {
      console.warn(err);
      Notify({
        type: "warning",
        icon: "alarm",
        title: "",
        text: "Unable to verify eLN ID (either eLN or the interface is down)",
        closeButton: true
      });
    } finally {
    }
  };

  const handleSubmit = async (isRunLog: boolean, values: ILogFormValues, actions: FormikHelpers<ILogFormValues>) => {
    if (isRunLog && "eLNid" in values && values.eLNid) {
      const eLNidValidation = await eLNidValidationCheck(values.eLNid);
      switch (eLNidValidation?.statusCode) {
        case 400:
          Notify({
            type: "warning",
            icon: "alarm",
            title: "",
            text: "Unable to verify eLN ID (either eLN or the interface is down)",
            closeButton: true
          });
          break;
        case 403:
          Notify({
            type: "warning",
            icon: "alarm",
            title: "",
            text: "Invalid eLN experiment ID",
            closeButton: true
          });
          break;
        case 200:
          break;
        default:
          Notify({
            type: "warning",
            icon: "alarm",
            title: "",
            text: `Unexpected response - status: ${eLNidValidation?.statusCode}`,
            closeButton: true
          });
      }
      if (eLNidValidation?.statusCode !== 200) {
        actions.setSubmitting(false);
        return;
      }
    }
    history.push("/");
    const { equipmentListWithDetails, ...formValues } = values;

    const logType = isRunLog ? "Run" : "Action";
    try {
      const missingIds = equipmentListWithDetails.filter((item) => !item.inventoryId);
      if (missingIds.length > 0) {
        throw new Error("non-existent Inventory ID");
      }
      const response = await client.mutate({
        mutation: isRunLog
          ? createRunLogBulkMutation(equipmentListWithDetails)
          : CREATE_BULK_DIGITAL_LAB_LOGBOOK_ACTION_LOG,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        variables: isRunLog
          ? createRunLogBulkVariables(formValues as IRunLogFormValues, equipmentListWithDetails, user, systemStatuses)
          : createActionLogBulkVariables(formValues as IActionLogFormValues, equipmentListWithDetails, user)
      });
      if (response.data === null || response.data.createBulkDigitalLabLogbookLogSheetChange === null)
        throw new Error("all failed");
      if (response.data || response.errors) {
        if (response.errors) {
          const idsFromErrors = (response.errors as { errorInfo: { inventoryId: string } }[]).map(
            (error) => error.errorInfo?.inventoryId
          );
          Notify({
            title: "",
            icon: "circle_confirm",
            type: "success",
            text: `Successfully added ${equipmentListWithDetails.length - idsFromErrors.length} ${logType} log(s)!`
          });
          Notify({
            title: "",
            type: "warning",
            icon: "alarm",
            text: `Failed to add ${logType} log(s) for ${idsFromErrors.join(", ")}`,
            toastOptions: {
              autoClose: false
            }
          });
        } else
          Notify({
            title: "",
            icon: "circle_confirm",
            type: "success",
            text: `Successfully added ${logType} log(s)!`
          });
      }
    } catch (error) {
      if ((error as Error).message === "non-existent Inventory ID")
        Notify({
          title: "",
          type: "warning",
          icon: "alarm",
          text: `Log edit/creation failed due to non-existent Inventory ID`,
          toastOptions: {
            autoClose: false
          }
        });
      else
        Notify({
          title: "",
          type: "warning",
          icon: "alarm",
          text: `Failed to add ${logType} log(s) for all selected equipment(s)!`
        });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: THEME["one-color-cobas-blue-50"],
        padding: THEME["one-spacer-16"],
        boxSizing: "border-box"
      }}
    >
      {equipmentListWithDetails ? (
        <Formik
          initialValues={
            isRunLog
              ? {
                  ...(runLogForm || runLogInitialValues),
                  equipmentListWithDetails
                }
              : {
                  ...(actionLogForm || actionLogInitialValues),
                  equipmentListWithDetails
                }
          }
          validationSchema={isRunLog ? runLogValidationSchema : actionLogValidationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(isRunLog, values as ILogFormValues, actions as FormikHelpers<ILogFormValues>);
          }}
        >
          <ItemWrapper setOpenDialog={setOpenDialog} openDialog={openDialog}>
            <StepperForm
              equipmentList={equipmentListWithDetails}
              setEquipmentListWithDetails={setEquipmentListWithDetails}
              client={client}
            />
          </ItemWrapper>
        </Formik>
      ) : (
        <CenteredSpinner />
      )}
    </div>
  );
});
