import { DLabDataGrid, DialogServiceContext, FileSelectIcon, THEME } from "@digitallab/grid-common-components";
import { OwcTypography, OwcButton, OwcIcon, OwcBanner } from "@one/react";
import { equipmentStatus } from "../constants";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LogsTopBar } from "./LogsTopBar";
import { Stepper } from "./Stepper";
import ActionDataInputsBox from "../features/log-book/log-sheet/log-sheet-form/ActionDataInputsBox";
import { COLUMNS_DEFINITIONS } from "./columnDefinitions";
import { useFormikContext } from "formik";
import RunDataInputsBox from "../features/log-book/run-logs/run-logs-form/RunDataInputsBox";
import {
  CloudSearchEquipmentModelType,
  DigitalLabLogbookInstrumentModelType
} from "../models/DigitalLabLogbookInstrumentModelType";
import { toQueryParameter } from "../components/shared/useSearchParameters";
import { SetEquipmentsForLogBookDialog } from "../components/shared/SetEquipmentsForLogBook/SetEquipmentsForLogBookDialog";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { IActionLogFormValues, InstrumentDetailsForStepperModelType, KEEP_STATUS_VALUE } from "./StepperContainer";
import { WithApolloClient } from "react-apollo";
import { loadEquipmentsData } from "./loadEquipmentData";

const FOUR_EP_SITES = process.env.REACT_APP_4EP_SITES || [""];
const ADD_EQUIPMENT_TEXT = "Add equipment from the list";
const initialSteps = [
  {
    mainText: "Select equipment",
    optionalText: "xxx"
  },
  {
    mainText: "Define run",
    optionalText: "Define the details of the run log"
  }
];
export const StepperForm = ({
  equipmentList: selectedEquipmentList = [],
  setEquipmentListWithDetails: setSelectedEquipmentList,
  client
}: {
  equipmentList: InstrumentDetailsForStepperModelType[];
  setEquipmentListWithDetails: React.Dispatch<React.SetStateAction<InstrumentDetailsForStepperModelType[] | undefined>>;
  client: WithApolloClient<any>;
}) => {
  //@ts-ignore
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  //@ts-ignore
  const systemStatuss = useSelector((store) => store.runLogsForm.systemStatuss);

  const { pathname } = useLocation();
  const isRunLog = pathname.includes("/logs/run");
  const formik = useFormikContext();
  const { submitForm } = formik;
  const history = useHistory();

  const [steps, setSteps] = useState(initialSteps);
  if (!isRunLog)
    steps[1] = {
      mainText: "Define action",
      optionalText: "Define the details of the action log"
    };
  const [presentStep, setPresentStep] = useState(0);
  const dialogService = useContext(DialogServiceContext);
  const [disableNextStep, setDisableNextStep] = useState(false);
  const [isPropagationDisabled, setIsPropagationDisabled] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const attributes = {
    height: "55vh",
    rowQuickSearch: false,
    suppressPaginationPanel: true,
    noRowsOverlayComponent: () => (
      <div style={{ textAlign: "center", pointerEvents: "auto" }}>
        <OwcIcon>
          <FileSelectIcon />
        </OwcIcon>
        <OwcTypography variant="body2">Selected equipment will appear here</OwcTypography>
        <OwcButton style={{ display: "inline-block" }} onClick={() => setOpenModal(true)}>
          {ADD_EQUIPMENT_TEXT}
        </OwcButton>
      </div>
    ),
    pagination: false,
    animateRows: true,
    rowMultiSelectWithClick: true,
    rowExport: false,
    masterDetail: true,
    hiddenByDefault: true,
    serverSideInfiniteScroll: false,
    suppressRowClickSelection: true,
    paginationConfig: { rowsPerPage: 0, total: 0 },
    disablePagination: true,
    defaultColDef: {
      flex: 1,
      minWidth: 90,
      suppressMenu: true,
      sortable: true
    },
    onGridReady: () => {}
  };

  useEffect(() => {
    if (selectedEquipmentList.length === 0) {
      setDisableNextStep(true);
      steps[0].optionalText = "No equipment selected";
      setSteps([...steps]);
    } else {
      steps[0].optionalText = `${selectedEquipmentList[0].equipmentModel}${
        selectedEquipmentList.length > 1 ? " +" + (selectedEquipmentList.length - 1) : ""
      }`;
      setSteps([...steps]);
      setDisableNextStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEquipmentList.length]);

  useEffect(() => {
    if (presentStep === 0 && !isRunLog) {
      if (selectedEquipmentList?.length === 1) {
        if (selectedEquipmentList[0].qualificationStatus) {
          const qualificationStatus = find(gxpReadys, {
            key: selectedEquipmentList[0].qualificationStatus
          }) ||
            find(gxpReadys, {
              value: selectedEquipmentList[0].qualificationStatus
            }) || {
              key: "",
              value: ""
            };
          formik.setFieldValue("gxpReady", qualificationStatus, true);
        }
        if (selectedEquipmentList[0].systemStatus) {
          const systemStatus =
            find(systemStatuss, {
              value: selectedEquipmentList[0].systemStatus
            }) ||
            find(systemStatuss, {
              key: selectedEquipmentList[0].systemStatus
            }) ||
            null;

          formik.setFieldValue("systemStatus", find(systemStatuss, systemStatus), true);
        }
        if (selectedEquipmentList[0].configurationDetails) {
          formik.setFieldValue(
            "logbookConfigurationDetails.newValue",
            selectedEquipmentList[0].configurationDetails,
            true
          );
          formik.setFieldValue("logbookConfigurationDetails.shouldBePublished", false, true);
        }
      } else {
        if (isPropagationDisabled) {
          formik.setFieldValue("gxpReady", KEEP_STATUS_VALUE, true);
          formik.setFieldValue("systemStatus", KEEP_STATUS_VALUE, true);
        } else {
          formik.setFieldValue("gxpReady", null, true);
          formik.setFieldValue("systemStatus", null, true);
        }
        formik.setFieldValue("logbookConfigurationDetails", { newValue: null, shouldBePublished: false }, true);
        formik.setFieldValue("updatedSoftwareVersion", { newValue: null, shouldBePublished: false }, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentStep, isPropagationDisabled, selectedEquipmentList.length]);

  useEffect(() => {
    const checkPropagationDisabled = () =>
      selectedEquipmentList.some((item) => {
        const site = FOUR_EP_SITES.includes(item.siteName);
        const group = item.belongingToGroup?.startsWith("DSR");
        const status = item.status === equipmentStatus.pending?.key;
        return (site && group) || status;
      });
    const disablePropagation = checkPropagationDisabled();
    setIsPropagationDisabled(disablePropagation);
  }, [selectedEquipmentList]);

  const handleCancel = () =>
    (
      dialogService.show({
        title: `Cancel Add ${isRunLog ? "Run" : "Action"} log(s)`,
        confirmText: "Cancel",
        cancelText: "Keep editing",
        body: "By canceling you will loose all the changes you have made."
      }) as Promise<unknown>
    )
      .then(() => history.goBack())
      .catch(() => {});

  const handleDelete = ({ data }: { data: DigitalLabLogbookInstrumentModelType }) => {
    const deleted = selectedEquipmentList.filter(
      (item) =>
        !(
          item.equipmentId === data.equipmentId &&
          (!data.serialNumber || item.serialNumber === data.serialNumber) &&
          item.equipmentModel === data.equipmentModel
        )
    );
    setSelectedEquipmentList(deleted);
    const newSearch = toQueryParameter(
      "equipmentIdList",
      deleted.map(({ inventoryId }) => inventoryId)
    );
    history.replace(pathname + newSearch);
  };

  useEffect(() => {
    formik.setFieldValue("equipmentListWithDetails", selectedEquipmentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEquipmentList]);

  return (
    <>
      <LogsTopBar titleText={`Add ${isRunLog ? "Run" : "Action"} log(s)`} onCancel={handleCancel} />

      <Stepper
        onCancel={handleCancel}
        presentStep={presentStep}
        steps={steps}
        nextStepDisabled={disableNextStep}
        setPresentStep={setPresentStep}
        finishText={`Add ${isRunLog ? "Run" : "Action"} log(s)`}
        finishDisabled={!formik.isValid}
        stepperStyle={{ width: "35%" }}
        isSubmitting={formik.isSubmitting}
        handleFinish={async () => {
          if (
            !isRunLog &&
            !isPropagationDisabled &&
            ((formik.values as IActionLogFormValues).systemStatus?.key !== KEEP_STATUS_VALUE.key ||
              (formik.values as IActionLogFormValues).gxpReady?.key !== KEEP_STATUS_VALUE.key ||
              (formik.values as IActionLogFormValues).updatedSoftwareVersion?.shouldBePublished)
          ) {
            const valuesToPropagate = [];
            if ((formik.values as IActionLogFormValues).systemStatus?.key !== KEEP_STATUS_VALUE.key)
              valuesToPropagate.push("System status");
            if ((formik.values as IActionLogFormValues).gxpReady?.key !== KEEP_STATUS_VALUE.key)
              valuesToPropagate.push("Qualification status");
            if ((formik.values as IActionLogFormValues).updatedSoftwareVersion?.shouldBePublished)
              valuesToPropagate.push("Software Version");
            if ((formik.values as IActionLogFormValues).logbookConfigurationDetails?.shouldBePublished)
              valuesToPropagate.push("Configuration details");
            (
              dialogService.show({
                title: "Propagate to repository",
                confirmText: "Proceed",
                body: `${valuesToPropagate
                  .join(", ")
                  .replace(/,([^,]*)$/, " and$1")} will be propagated to repository. Do you want to proceed?`
              }) as Promise<unknown>
            )
              .then(() => {
                submitForm();
              })
              .catch(() => {});
          } else submitForm();
        }}
      />
      <div
        id="Form body"
        style={{
          width: "100%",
          backgroundColor: THEME["one-color-cobas-accent-gray-100"],
          padding: THEME["one-spacer-16"],
          paddingBottom: THEME["one-spacer-68"]
        }}
      >
        {presentStep === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div style={{ marginBottom: THEME["one-spacer-16"] }}>
              <OwcTypography variant="title5">Selected equipment</OwcTypography>
              <OwcTypography>Add the equipment for which you want to define an action</OwcTypography>
            </div>

            <div style={{ padding: THEME["one-spacer-16"] }}>
              <DLabDataGrid
                rowData={selectedEquipmentList.map((item) => item)}
                columnDefs={COLUMNS_DEFINITIONS(handleDelete)}
                {...attributes}
              />
            </div>
            {selectedEquipmentList.length !== 0 && (
              <OwcButton
                style={{
                  width: 400,
                  marginTop: 32,
                  alignSelf: "flex-end"
                }}
                onClick={() => setOpenModal(true)}
                variant="secondary"
              >
                {ADD_EQUIPMENT_TEXT}
              </OwcButton>
            )}
            <SetEquipmentsForLogBookDialog
              cancelTitle={"Cancel"}
              columnDefs={COLUMNS_DEFINITIONS(handleDelete)}
              initiallySelectedEquipments={selectedEquipmentList}
              key={JSON.stringify(selectedEquipmentList)}
              approveTitle={"Add"}
              open={openModal}
              close={() => setOpenModal(false)}
              title={ADD_EQUIPMENT_TEXT}
              onDialogApproveCallBack={async (
                data: {
                  id: string;
                  rowData: CloudSearchEquipmentModelType;
                }[]
              ) => {
                const addedEquipment = await loadEquipmentsData(
                  data.map((x) => x.id),
                  client,
                  history,
                  pathname
                );
                setOpenModal(false);
                const newSearch = toQueryParameter(
                  "equipmentIdList",
                  [...selectedEquipmentList, ...addedEquipment].map(({ inventoryId }) => inventoryId)
                );
                history.replace(pathname + newSearch);
                setSelectedEquipmentList([...selectedEquipmentList, ...addedEquipment]);
              }}
            />
          </div>
        )}
        {presentStep === 1 && (
          <>
            <div style={{ marginBottom: THEME["one-spacer-16"] }}>
              {isPropagationDisabled && (
                <OwcBanner type="info" style={{ top: 0, marginBottom: THEME["one-spacer-12"] }}>
                  Propagation to Equipment Repository is not available. You have selected at least 1 equipment that
                  requires additional review. Contact the Equipment Responsible Person if you want to provide update to
                  Equipment Repository.
                </OwcBanner>
              )}
              <OwcTypography variant="title5">Defined {isRunLog ? "run" : "action"}</OwcTypography>
              {isRunLog ? (
                <RunDataInputsBox equipmentDetail={undefined} />
              ) : (
                <>
                  <ActionDataInputsBox
                    numberOfSelectedEquipment={selectedEquipmentList.length}
                    isPropagationDisabled={isPropagationDisabled}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
